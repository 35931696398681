import React, { useEffect, useCallback, useRef, useMemo } from "react";
import "./infra.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchSchoolStatsData } from "../../redux/thunks/dashboardThunk";
import { fetchSchoolStatsDataYear } from "../../redux/thunks/dashboardThunk";
import { fetchArchiveServicesEnrolmentRelatedIndicators } from "../../redux/thunks/archiveServicesThunk";
import {
  nationalWiseName,
  stateWiseName,
  districtWiseName,
  district,
  blockWiseName,
  block,
  generateTextContent,
  excelStyles,
} from "../../constants/constants";

import { styles } from "../../pdfAndExcellUtils/pdfAndExcellUtils";
import allreportsdata from "../../json-data/allreports.json";
import allReportsHindidata from "../../json-data/allReportsHindi.json";
import { ScrollToTopOnMount } from "../Scroll/ScrollToTopOnMount";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import groupByKey from "../../utils/groupBy";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  initialFilterSchoolData,
  initialFilterEnrollmet4014Data,
} from "../../constants/constants";
import HC_more from "highcharts/highcharts-more";
import FilterDropdown3016 from "../Home/filter/FilterDropdown3016";
import { useLocation, useSearchParams } from "react-router-dom";
import { GlobalLoading } from "../GlobalLoading/GlobalLoading";
import { categoryMappings as categoryMappingsOriginal } from "../../constants/constants";
import { removeAllDistrict } from "../../redux/thunks/districtThunk";
import { removeAllBlock } from "../../redux/thunks/blockThunk";
import { handleActiveTabs } from "../../redux/slice/headerSlice";
import { useTranslation } from "react-i18next";
import satyamevaimg from "../../assets/images/satyameva-jayate-img.png";
import udise from "../../assets/images/udiseplu.jpg";
import useReportOverallLocationSum from "../../CustomHook/useReportOverallLocationSum";
import FilterDropdown4000 from "../Home/filter/FilterDropdown2007";
import FilterDropdown4014 from "../Home/filter/FilterDropdown4014";
import { fetchYearData } from "../../redux/thunks/yearThunk";

import useReportOverallRegionAverage from "../../CustomHook/useReportOverallRegionAverage";
import center from "@turf/center";
import {
  exportToPDF,
  useExportToExcel,
} from "../../pdfAndExcellUtils/pdfAndExcellUtils";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/export-data.js")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/treemap")(Highcharts);
require("highcharts/modules/treegraph")(Highcharts);
HC_more(Highcharts);

export default function EnrollmentReport4000() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [queryParameters] = useSearchParams();
  const id = queryParameters.get("id");
  const type = queryParameters.get("type");
  const enrollmentFilter = useSelector((state) => state.enrollmentFilter);
  const enrollment4014SliceData = useSelector(
    (state) => state.enrollment4014?.data?.data
  );


  const headerData = useSelector((state) => state.header);
  const [local_state, setLocalStateName] = useState("All India/National");
  const [local_district, setLocalDistrictName] = useState("District");
  const [local_block, setLocalBlockName] = useState("Block");
  const local_year = window.localStorage.getItem("year");
  const stateName = localStorage.getItem("state");
  const filterObj = structuredClone(enrollmentFilter);
  const [report, setReport] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [activeTab, setActiveTab] = useState(type);
  const [isScrollComplete, setIsScrollComplete] = useState(false);
  const[transActiveKey, setTransActiveKey]=useState("promotionRate")
  dispatch(handleActiveTabs(activeTab));
  useEffect(() => {
    dispatch(removeAllDistrict());
    dispatch(removeAllBlock());
  }, []);
  useEffect(() => {
    window.localStorage.setItem("map_state_name", "All India/National");
    window.localStorage.setItem("state", "All India/National");
    window.localStorage.setItem("state_wise", "All India/National");
    window.localStorage.setItem("map_district_name", "District");
    window.localStorage.setItem("district", "District");
    window.localStorage.setItem("block", "Block");
  }, []);
  useEffect(() => {
    localStorage.setItem("reportName", report?.report_name);
  }, [report?.report_name]);
  useEffect(() => {
    setLocalStateName(localStorage.getItem("state"));
    setLocalStateName(localStorage.getItem("state_wise"));
    setLocalStateName(localStorage.getItem("map_state_name"));
    setLocalDistrictName(localStorage.getItem("map_district_name"));
    setLocalBlockName(localStorage.getItem("block"));
  }, [filterObj, headerData.activeTab]);
  const [groupKeys, setGroupKeys] = useState({
    rateWise: true,
    categoriesWise: false,
  });
  const [gridRefreshKey, setGridRefreshKey] = useState(0);
  const [multiRate, setMultiRate] = useState("multibtn");
  const [multiSoc, setMultiSoc] = useState("multibtn");
  const [multiCat, setMultiCats] = useState("");

  const [rateState, setRateState] = useState({
    promotionRate: "active",
    repetitionRate: "",
    dropoutRate: "",
    transitionRate: "",
    all: "",
  });

  const [categoryState, setCategoryState] = useState({
    primary: "",
    upperPrimary: "",
    elementary: "",
    secondary: "",
    higherSecondary: "",
    all: "active",
    socialCategory: "active",
  });

  const [showTransposedSocial, setShowTransposedSocial] = useState(false);
  const [averages, setAverage] = useState();
  const [count, setCount] = useState(0);
  const ratePrefixes = {
    1: "DropoutRate",
    2: "PromotionRate",
    3: "RepetitionRate",
    4: "TransitionRate",
  };

  useEffect(() => {
    if (categoryState.socialCategory === "active") {
      filterObj.itemId = 0;
      dispatch(
        fetchArchiveServicesEnrolmentRelatedIndicators({
          ...filterObj,
          yearId: headerData.selectYearId,
        })
      );
    } else {
      delete filterObj.itemId;
      dispatch(
        fetchArchiveServicesEnrolmentRelatedIndicators({
          ...filterObj,
          yearId: headerData.selectYearId,
        })
      );
    }
  }, [categoryState.socialCategory]);

  const [groupedRow, setGroupedRow] = useState({});

  const dataArrayforAgTable =
    groupedRow && Object.keys(groupedRow).length > 0
      ? Object.values(groupedRow).filter((item) => item.itemId !== "5")
      : [];
     
  const result = useReportOverallRegionAverage(dataArrayforAgTable,showTransposedSocial);
  const groupDataByItemGroupId = (data, selectedRates) => {
    if (!data || !selectedRates) return [];
    return data.filter((item) =>
      selectedRates.includes(Number(item.itemGroupId))
    );
  };

  const [columnsUpdated, setColumn] = useState([]);
  const filter_query_by_location =
    // local_state === "All India/National" ||
    local_state === "State Wise" ||
    local_district === "District Wise" ||
    local_block === "Block Wise";
  const getColumns = (categoryState, rateState) => {
    const baseColumns = [
      {
        headerName: "Serial Number",
        field: "Serial Number",
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        headerClass: "headerStyle",
      },
      {
        headerName: "",
        headerClass: "headerStyle",

        children: [
          {
            headerName: "",
            headerClass: "headerStyle",

            children: [
              {
                headerName: "Location",
                field: "regionName",
                filter: true,
                hide: !filter_query_by_location,
                headerClass: "headerStyle",
              },
            ],
          },
        ],
      },
      {
        headerName: "",
        headerClass: "headerStyle",

        children: [
          {
            headerName: "",
            headerClass: "headerStyle",

            children: [
              showTransposedSocial === true
                ? {
                  headerName: "Rate",
                  field: "itemGroupId",
                  headerClass: "headerStyle",
                }
                : {
                  headerName: "Social Category",
                  field: "socialCat",
                  filter: true,
                  hide: categoryState.socialCategory !== "active",
                  headerClass: "headerStyle",
                },
            ],
          },
        ],
      },
    ];

    if (
      categoryState.socialCategory !== "active" &&
      categoryState.all !== "active" &&
      !Object.values(categoryState).some((val) => val === "active")
    ) {
      return baseColumns;
    }

    const categoryColumns = [];

    const rateTypes = [
      {
        key: "promotionRate",
        label: "Promotion Rate",
        prefix: "PromotionRate",
      },
      {
        key: "repetitionRate",
        label: "Repetition Rate",
        prefix: "RepetitionRate",
      },
      { key: "dropoutRate", label: "Drop Out Rate", prefix: "DropoutRate" },
      {
        key: "transitionRate",
        label: "Transition Rate",
        prefix: "TransitionRate",
      },
    ];

    const rateTypesTranspose = [
      {
        key: transActiveKey,
        label: "General",
        prefix: "General",
      },
      {
        key: transActiveKey,
        label: "SC",
        prefix: "SC",
      },
      {
        key: transActiveKey,
        label: "ST",
        prefix: "ST",
      },
      {
        key: transActiveKey,
        label: "OBC",
        prefix: "OBC",
      },

    ];


    const activeRatesSocial =
      rateState.all === "active"
        ? rateTypesTranspose
        : rateTypesTranspose.filter(
          (rateType) => rateState[rateType.key] === "active"
        );



    const activeRates =
      rateState.all === "active"
        ? rateTypes
        : rateTypes.filter(
          (rateType) => rateState[rateType.key] === "active"
        );

    const transposeActiveRates = showTransposedSocial === true ? activeRatesSocial : activeRates


    // Helper to create sub-columns for Boys, Girls, and Total
    const createSubColumns = (prefix) => [

      {
        headerName: "Boys",
        field: `${prefix}B`,
        hide: false,
        halign: center,
        headerClass: "headerStyle",
      },
      {
        headerName: "Girls",
        field: `${prefix}G`,
        hide: false,
        halign: center,
        headerClass: "headerStyle",
      },
      {
        headerName: "Total",
        field: `${prefix}T`,
        hide: false,
        halign: center,
        headerClass: "headerStyle",
      },
    ];



    // const addCategoryColumns = (categoryName, parentHeader, prefixSuffix) => {
    //   if (
    //     categoryState.all === "active" ||
    //     categoryState[categoryName] === "active"
    //   ) {
    //     categoryColumns.push({
    //       headerName: parentHeader,
    //       headerClass: "headerStyle",
    //       hide: false,
    //       children: transposeActiveRates.map((rateType) => ({
    //         headerName: rateType.label,
    //         headerClass: "headerStyle",
    //         colSpan: 3,
    //         children: createSubColumns(`${rateType.prefix}_${prefixSuffix}`),
    //       })),
    //     });
    //   }
    // };

    const addCategoryColumns = (categoryName, parentHeader, prefixSuffix) => {
      if (
        categoryState.all === "active" ||
        categoryState[categoryName] === "active"
      ) {
        categoryColumns.push({
          headerName: parentHeader,
          headerClass: "headerStyle",
          hide: false,
          children: showTransposedSocial
            ? [
                {
                  headerName: "Social Category",
                  filter: true,
                  headerClass: "headerStyle",
                  children: transposeActiveRates.map((rateType) => ({
                    headerName: rateType.label,
                    headerClass: "headerStyle",
                    colSpan: 3,
                    children: createSubColumns(`${rateType.prefix}_${prefixSuffix}`),
                  })),
                },
              ]
            : transposeActiveRates.map((rateType) => ({
                headerName: rateType.label,
                headerClass: "headerStyle",
                colSpan: 3,
                children: createSubColumns(`${rateType.prefix}_${prefixSuffix}`),
              })), 
        });
      }
    };
    addCategoryColumns("primary", "Primary", "pri");
    addCategoryColumns("upperPrimary", "Upper Primary", "upri");
    addCategoryColumns("elementary", "Elementary", "elem");
    addCategoryColumns("secondary", "Secondary", "sec");
    addCategoryColumns("higherSecondary", "higher Secondary", "hsec");

    return [...baseColumns, ...categoryColumns];
  };

  const columns = getColumns(categoryState, rateState);

  useEffect(() => {
    setColumn(columns);
  }, [categoryState, rateState, showTransposedSocial]);

  const handleRateWiseClick = (value) => {
    setRateState((prev) => {
      const newState = { ...prev, all: "" };
      if (value === "all") {
        newState.all = prev.all === "active" ? "" : "active";
        Object.keys(newState).forEach((key) => {
          if (key !== "all") newState[key] = "";
        });
      } else {
        newState[value] = prev[value] === "active" ? "" : "active";
        newState.all = "";
      }
      const activeKey = Object.keys(newState).find(
        (key) => newState[key] === "active"
      );
      setTransActiveKey(activeKey)
      return newState;
    });
  };

  const handleCategoryClick = (value) => {
    setCategoryState((prev) => {
      const newState = { ...prev };
      if (value === "all") {
        newState.all = prev.all === "active" ? "" : "active";
        Object.keys(newState).forEach((key) => {
          if (key !== "all" && key !== "socialCategory") newState[key] = "";
        });
      } else {
        newState[value] = prev[value] === "active" ? "" : "active";
        if (value !== "socialCategory") newState.all = "";
      }
      if (newState.socialCategory === "") {
        setMultiSoc("");
      } else if (newState.socialCategory === "active") {
        setMultiSoc("multibtn");
      }
      return newState;
    });
  };

  const handleGroupButtonClicks = (value) => {
    setGridRefreshKey((prevKey) => prevKey + 1);
    if (value === "Rate Wise") {
      setGroupKeys({ rateWise: true, categoriesWise: false });
      setMultiRate("multibtn");
      setMultiCats("");
    } else if (value === "Categories") {
      setGroupKeys({ rateWise: false, categoriesWise: true });
      setMultiRate("");
      setMultiCats("multibtn");
    }
  };

  useEffect(() => {
    handleGroupButtonClicks("Rate Wise");
    setRateState((prev) => ({ ...prev, promotionRate: "active" }));
    setCategoryState((prev) => ({
      ...prev,
      all: "active",
      socialCategory: "active",
    }));
  }, []);
  // ....end.... handle buttons............................
  //...start......... group data in sinle object and also add socialCat for itemId.....................
  const groupDataToSingleObject = (data) => {
    if (!data || !data.length) return [];
    const groupedData = [];
    const socialCatMap = {
      1: "General",
      2: "SC",
      3: "ST",
      4: "OBC",
      5: "Muslim",
    };

    const seenGroups = new Map();

    data.forEach((item) => {
      const prefix = ratePrefixes[item.itemGroupId];
      const itemIdKey = `${item.itemId}`;
      const regionName = `${item.regionName}`;
      const groupKey = `${regionName}_${itemIdKey}`;

      if (!seenGroups.has(groupKey)) {
        const group = {
          yearId: item.yearId,
          regionType: item.regionType,
          regionCode: item.regionCode,
          regionName: item.regionName,
          itemId: item.itemId,
          socialCat: socialCatMap[item.itemId] || "",
        };
        seenGroups.set(groupKey, group);
        groupedData.push(group);
      }

      const group = seenGroups.get(groupKey);
      Object.keys(item).forEach((key) => {
        if (
          ![
            "yearId",
            "regionType",
            "regionCode",
            "regionName",
            "itemGroupId",
            "itemId",
          ].includes(key)
        ) {
          group[`${prefix}_${key}`] = item[key];
        }
      });
    });

    return groupedData;
  };

  //...end......... group data in sinle object and also add socialCat for itemId.....................

  //.......start .........group data rate wise .......................
  // when transpose data then group data
  function groupDataByItemGroupIdAndPrefixKeys(data) {
    const prefixMap = {
      1: "General",
      2: "SC",
      3: "ST",
      4: "OBC",
      5: "MUSLIM",
    };

    const itemGroupTypeMap = {
      1: "Dropout Rate",
      2: "Promotion Rate",
      3: "Repetition Rate",
      4: "Transition Rate"
    };

    const groupedData = {};

    data.forEach((item) => {
      const { itemGroupId, itemId, yearId, regionType, regionCode, regionName, ...rest } = item;
      const key = `${itemGroupId}_${regionName}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          yearId,
          regionType,
          regionCode,
          regionName,
          [`itemGroupId`]: itemGroupTypeMap[itemGroupId]
        };
      }
      const prefix = prefixMap[itemId];
      Object.entries(rest).forEach(([k, value]) => {
        if (prefix && k in rest) {
          groupedData[key][`${prefix}_${k}`] = value;
        }
      });
    });

    return Object.values(groupedData);
  }



  useEffect(() => {
    const activeRates = [];
    if (rateState.all === "active") {
      activeRates.push(1, 2, 3, 4);
    } else {
      if (rateState.dropoutRate === "active") activeRates.push(1);
      if (rateState.promotionRate === "active") activeRates.push(2);
      if (rateState.repetitionRate === "active") activeRates.push(3);
      if (rateState.transitionRate === "active") activeRates.push(4);
    }

    const filteredData = groupDataByItemGroupId(
      enrollment4014SliceData,
      activeRates
    );

    if (showTransposedSocial) {
      const filterDataForTranspose=groupDataByItemGroupIdAndPrefixKeys(filteredData)
      setGroupedRow(filterDataForTranspose);
    } else {
      const singleData=groupDataToSingleObject(filteredData)
      setGroupedRow(singleData);
    }
  }, [rateState, enrollment4014SliceData, showTransposedSocial]);

  //.......end .........group data rate wise .......................

  const [defColumnDefs] = useState({
    flex: 1,
    minWidth: 150,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    filter: true,
    autoHeaderHeight: true,
    headerClass: "headerStyle",
  });

  useEffect(() => {
    const reportsData =
      i18n.language === "hi" ? allReportsHindidata : allreportsdata;

    for (const category in reportsData) {
      const foundReport = reportsData[category].find(
        (report) => report.id === parseInt(id)
      );
      if (foundReport) {
        setReport(foundReport);
        break;
      }
    }
  }, [id, i18n.language]);

  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);

  /*------------Export data to Excel and PDF-------------*/

  const handleScrollComplete = () => {
    setIsScrollComplete(true);
  };

  const switchColumnsToRowsSocial = (value) => {
    if (value === "transpose") {
      setShowTransposedSocial(true);
      if (showTransposedSocial === true) {
        setShowTransposedSocial(false);
      }
    }
  };

  /*------------Export data to Excel and PDF-------------*/

  const { exportToExcel } = useExportToExcel(
    gridApi,
    report,
    local_year,
    stateName,
    nationalWiseName,
    stateWiseName,
    local_district,
    district,
    districtWiseName,
    local_block,
    block,
    blockWiseName,
    generateTextContent
  );
  const handleExportData = (e) => {
    const { value } = e.target;
    if (value === "export_pdf") {
      exportToPDF({
        columns,
        data: result,
        reportName: report.report_name,
        localYear: local_year,
        satyamevaImg: satyamevaimg,
        udiseImg: udise,
        stateName,
        nationalWiseName,
        district,
        block,
        generateTextContent,
      });
    }
    if (value === "export_excel") {
      exportToExcel();
    }
    document.getElementById("export_data").selectedIndex = 0;
  };

  const handleFilterChanged = (gridApi) => {
    // Get all displayed columns
    const columns = gridApi.api.getAllDisplayedColumns();
    // Define function to get the cell value for export
    const getCellToExport = (column, node) =>
      gridApi.api.getValue(column, node) ?? "";

    // Initialize rows to export
    const rowsToExport = [];
    gridApi.api.forEachNodeAfterFilterAndSort((node) => {
      const rowToExport = { id: rowsToExport.length + 1 }; // Add an ID field
      columns.forEach((column) => {
        const colId = column.getColId(); // Get the column ID
        rowToExport[colId] = getCellToExport(column, node); // Use column ID as key
      });
      rowsToExport.push(rowToExport);
    });
    setCount(count + 1);
    setAverage(rowsToExport);
  };

  /*----------------------Calculate  Average Show in bootom inside AgGrid----------------------------*/

  const calculateAverages = (dataArrayforAgTable) => {
    const totalSums = {};
    const average = {};
    dataArrayforAgTable.forEach((item) => {
      for (const [key, value] of Object.entries(item)) {
        if (!isNaN(parseFloat(value))) {
          totalSums[key] = (totalSums[key] || 0) + parseFloat(value);
        }
      }
    });

    for (const [key, value] of Object.entries(totalSums)) {
      average[key] = (value / dataArrayforAgTable.length).toFixed(2);
      totalSums[key] = value.toFixed(2);
    }
    totalSums.socialCat = "Overall";
    average.socialCat = "Overall";
    return { totalSums, average };
  };

  const { totalSums, average } = calculateAverages(
    count > 0 ? averages : dataArrayforAgTable
  );

  return (
    <>
      <ScrollToTopOnMount onScrollComplete={handleScrollComplete} />
      <section className="infrastructure-main-card p-0" id="content">
        <div className="bg-grey2 pb-0 pt-0 header-bar tab-for-graph">
          <div className="blue-strip">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-10 col-lg-10">
                  <div className="common-content text-start map-heading-map">
                    {report && (
                      <div className="common-content text-start map-heading-map d-flex align-items-center">
                        <span className="me-3">
                          {t("reports_id")} {report.id}
                        </span>
                        <h2 className="heading-sm1 mb-0 mt-0">
                          {report.report_name}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
                {activeTab !== "about" && activeTab !== "graph" && (
                  <div className="col-md-2 col-lg-2">
                    <div className="select-infra button-group-filter">
                      <div className="indicator-select">
                        <select
                          id="export_data"
                          className="form-select bg-grey2"
                          onChange={handleExportData}
                          defaultValue={""}
                        >
                          <option className="option-hide">
                            {t("download_report")}
                          </option>
                          <option value="export_pdf">
                            {t("download_report")}{" "}
                          </option>
                          <option value="export_excel">
                            {t("download_as_excel")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container header-p-extra pb-4">
            <div className="row">
              {activeTab !== "about" && activeTab !== "graph" && (
                <div className="col-md-12 col-lg-12 d-flex align-items-center">
                  <div className="tab-text-infra me-4">{t("view_data_by")}</div>
                  <ul className="nav nav-tabs mul-tab-main">
                    {/* Rate Wise Group */}
                    <li className={`nav-item ${multiRate}`}>
                      <button
                        type="button"
                        className={`nav-link dark-active active`}
                        onClick={() => handleGroupButtonClicks("Rate Wise")}
                      >
                        Rate Wise
                      </button>
                      {[
                        "promotionRate",
                        "repetitionRate",
                        "dropoutRate",
                        "transitionRate",
                        "all",
                      ].map((rate) => (
                        <button
                          key={rate}
                          type="button"
                          className={`nav-link dark-active details-multi ${rateState[rate]}`}
                          onClick={() => handleRateWiseClick(rate)}
                        >
                          {rate.replace(/([A-Z])/g, " $1").trim()}
                        </button>
                      ))}
                    </li>

                    {/* Categories Group */}
                    <li className={`nav-item ${multiCat}`}>
                      <button
                        type="button"
                        className={`nav-link dark-active1 active`}
                        onClick={() => handleGroupButtonClicks("Categories")}
                      >
                        Categories
                      </button>
                      {[
                        "primary",
                        "upperPrimary",
                        "elementary",
                        "secondary",
                        "higherSecondary",
                        "all",
                      ].map((category) => (
                        <button
                          key={category}
                          type="button"
                          className={`nav-link details-multi dark-active1 ${categoryState[category]}`}
                          onClick={() => handleCategoryClick(category)}
                        >
                          {category.replace(/([A-Z])/g, " $1").trim()}
                        </button>
                      ))}
                    </li>

                    {/* Social Category */}
                    <li className={`nav-item ${multiSoc}`} >
                      <button
                        type="button"
                        className={`nav-link dark-active1 ${categoryState.socialCategory}`}
                        onClick={() => handleCategoryClick("socialCategory")}
                      >
                        Social Category
                      </button>

                      <button
                        type="button"
                        className={`nav-link dark-active details-multi`}
                        onClick={(e) => switchColumnsToRowsSocial("transpose")}
                      >
                        {showTransposedSocial ? "By-Column" : "By-Rows"}
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-grey ptb-30">
          <div className="container tab-for-graph">
            <div className="row align-items-center report-inner-tab">
              <div className="col-md-12 col-lg-12 table-text-i table-brud-card">
                {activeTab !== "about" && (
                  <h4 className="brudcrumb_heading">
                    {t("showing_result_for")}
                    <span>&nbsp;{local_state}</span>
                    <span className="material-icons-round">chevron_right</span>
                    {local_district !== "District" && (
                      <>
                        <span>{local_district}</span>
                        <span className="material-icons-round">
                          chevron_right
                        </span>
                      </>
                    )}
                    {local_block !== "Block" && (
                      <>
                        <span>{local_block}</span>
                        <span className="material-icons-round">
                          chevron_right
                        </span>
                      </>
                    )}
                    <span>{local_year}</span>
                  </h4>
                )}
                <Tabs
                  defaultActiveKey={type}
                  id="uncontrolled-tab-example"
                  // onSelect={handleTopFiveTabs}
                  className="nav-absolute"
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Tab eventKey="about" title={t("about")}>
                    <div className="about-card mt-4">
                      <h2 className="heading-sm2 mb-2">{t("about_us")}</h2>
                      <p> {t("about_us_reports.report_1005.para1")} </p>
                      <p> {t("about_us_reports.report_1005.para2")}</p>
                      <p> {t("about_us_reports.report_1005.para3")}</p>
                      <p> {t("about_us_reports.report_1005.para4")}</p>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="table"
                    title={t("table")}
                    className="tabledata-ukkl"
                  >
                    {/* {school_data?.isLoading && <GlobalLoading />} */}
                    <div
                      className="ag-theme-material ag-theme-custom-height ag-theme-quartz h-300"
                      style={{ height: 450 }}
                    >
                      {/* stateName === stateWiseName ? result :  */}
                      <AgGridReact
                        onFilterChanged={() => handleFilterChanged(gridApi)}
                        key={gridRefreshKey}
                        rowData={
                          stateName === stateWiseName && !showTransposedSocial
                            ? result
                            : dataArrayforAgTable
                        }
                        getRowStyle={(params) => {
                          if (params.data.isTotalRow) {
                            return { fontWeight: "bold" };
                          }
                          return {};
                        }}
                        columnDefs={columns}
                        defaultColDef={defColumnDefs}
                        onGridReady={onGridReady}
                        groupDisplayType="custom"
                        groupHideOpenParents={true}
                        pinnedBottomRowData={[
                          dataArrayforAgTable.length > 0 &&
                            !showTransposedSocial
                            ? { socialCat: "Overall", ...average }
                            : "abc",
                        ]}
                        excelStyles={excelStyles}
                        // onColumnVisible={onColumnVisible}
                        suppressExcelExport={false} 
                      />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FilterDropdown4014 />
    </>
  );
}
